import SideMenu from './admin/_SideMenu';
import DataList from './admin/_DataList';
import DataForm from './admin/_DataForm';
import PageNavi from './admin/_PageNavi';
import Modal from './admin/_Modal';
import Accordion from './admin/_Accordion';
import InputEmail from './admin/_InputEmail';
import SlickSlider from './mypage/_SlickSlider';

class Admin {
	constructor() {
		this.view = $(window);
		this.view.document = $(document);

		this.media();
		this.media.breakpoint = 767;

		this.sideMenu = new SideMenu(this);
		this.dataList = new DataList(this);
		this.dataForm = new DataForm(this);
		this.pageNavi = new PageNavi(this);
		this.modal = new Modal(this);
		this.accordion = new Accordion(this);
		this.accordion = new InputEmail(this);
		this.slickslider = new SlickSlider(this);

		this.view.resize(e => { this.resize(e) })
	}

	media (device) {
		this.media.device = window.matchMedia(`(max-width: ${this.media.breakpoint}px)`).matches ? 'SP' : 'PC';
		return device && device.toUpperCase() == this.media.device;
	}

	resize () {
		if(!this.media(this.media.device)) {
			if(this.media('PC')) {
				this.view.trigger('resize:responsive');
				this.view.trigger('resize:responsive:PC');
			}
			if(this.media('SP')) {
				this.view.trigger('resize:responsive');
				this.view.trigger('resize:responsive:SP');
			}
		}
	}
}

window.admin = new Admin();
