export default class CheckGroup {
	constructor(item) {
		this.view = $(item);
		this.view.manager = this.view.children('dt').find('input[type="checkbox"]');
		this.view.members = this.view.children('dd').find('input[type="checkbox"]');
		this.view.manager.change(e => { this.managerChange(e) });
		this.view.members.change(e => { this.membersChange(e) });
		this.view.parent = this.view.parents('.checkbox-group');

		this.view.data('instance', this);
		this.membersChange();

		if(this.view.closest('.accordion-box').length && this.view.closest('.accordion-box').children('.accordion-trigger').length) {
			if(this.view.members.filter(':checked').length) {
				this.view.parents('.accordion-box').children('.accordion-trigger').addClass('active');
			}
		}
	}
	managerChange(e) {
		if(this.view.manager.prop('checked')) {
			this.view.members.prop('checked', true);
		} else {
			this.view.members.prop('checked', false);
		}
		this.bubbling();
	}
	membersChange(e) {
		if(this.view.members.filter(':checked').length == this.view.members.length && this.view.members.length > 0) {
			this.view.manager.prop('checked', true);
		} else {
			this.view.manager.prop('checked', false);
		}
		this.bubbling();
	}
	bubbling() {
		if(this.view.parent.length) {
			this.view.parent.data('instance').membersChange();
		}
	}
}
