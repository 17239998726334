export default class InputEmail {
	constructor() {
		this.view = $('input[name="email_input"]');
		if(!this.view.length) {
			return;
		}
		this.view.on('input',(e)=>{this.input(e)})
	}
	input(e){
		this.setData(this.view .val());
	}
	setData(data){
		let emailAddress = data + '@sankogakuen.jp';
		this.view.siblings('input[name="email"]').val(emailAddress);
		if(!data){
			this.view.siblings('input[name="email"]').val('');
		}
	}
}
