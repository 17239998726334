export default class SideMenu {

	constructor(parent) {
		this.parent = parent;
		this.view = $('.sidemenu');
		this.view.account = this.view.find('.account');
		this.view.list = this.view.find('.list')
		this.view.list.item = this.view.list.children();
		this.view.burger = this.view.find('.burger');
		this.view.burger.click(this.humberger.bind(this));

		this.current(location.href);

		$(window).on('resize:responsive:PC', () => {
			this.humberger(false);
		});
	}
	current(href) {
		var parent = null;
		var child = null;

		this.view.list.item.each((index, item) => {
			$(item).find('dt').find('a').each((i, element) => {
				if(location.href.indexOf(element.href) == 0) {
					if(!parent || (parent.href.length < element.href.length)) {
						parent = element;
					}
				}
			})
			$(item).find('dd').find('a').each((i, element) => {
				if(location.href.indexOf(element.href) == 0) {
					if(!child || (child.href.length < element.href.length)) {
						child = element;
					}
				}
			})
		});
		if(parent) {
			$(parent).addClass('active');
			$(parent).closest('dt').addClass('active');
		}
		if(child) {
			$(child).addClass('active');
			$(child).closest('dd').addClass('active');
		}
	}
	humberger(flag) {

		if(flag === true) {
			this.view.list.addClass('active');
			this.view.burger.addClass('active');
		} else if(flag === false) {
			this.view.list.removeClass('active');
			this.view.burger.removeClass('active');
		} else {
			this.view.list.toggleClass('active');
			this.view.burger.toggleClass('active');
		}
		if(this.view.list.hasClass('active')) {
			bodyScrollLock.disableBodyScroll(this.view.list.get(0));
		} else {
			bodyScrollLock.clearAllBodyScrollLocks();
		}
	}
}
