export default class Select {
	constructor(item) {
		this.view = $(item);
		this.view.change(e => { this.change(e) });
		this.change();
	}
	change() {
		if(!this.view.val()) {
			this.view.addClass('placeholder')
		} else {
			this.view.removeClass('placeholder');
		}
	}
}
