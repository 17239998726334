import DataSearch from './_DataSearch'

export default class DataList {

	constructor(parent) {
		this.parent = parent;
		this.view = $('.data-list');

		if(!this.view.length) {
			return;
		}
		this.view.checkmenu = this.view.find('.checkmenu');
		this.view.checkmenu.checkbox = this.view.checkmenu.find('input[type="checkbox"]');
		this.view.checkmenu.alltocheck = this.view.checkmenu.find('.alltocheck');
		this.view.checkmenu.alluncheck = this.view.checkmenu.find('.alluncheck');
		this.view.checkboxes = this.view.find('tbody .check input[type="checkbox"]');
		this.view.firstcells = this.view.find('tbody tr td:nth-child(2)');
		this.view.heads = this.view.find('thead th');
		this.view.rows = this.view.find('tbody tr');

		this.parent.view.document.click((e) => { this.checkmenu(e) });
		this.view.checkmenu.alltocheck.click(e => { this.alltocheck(e) });
		this.view.checkmenu.alluncheck.click(e => { this.alluncheck(e) });
		this.view.checkboxes.change(e => { this.onchange(e) });
		this.view.firstcells.click(e => {this.accordion(e) });

		this.search = new DataSearch(this.parent);

		this.setSmartPhoneLabels();
	}
	setSmartPhoneLabels() {
		var texts = [];
		this.view.heads.each((i, head) => {
			texts.push($(head).text());
		});
		this.view.rows.each((i, row) => {
			$(row).find('td').each((j, cell) => {
				if($(cell).hasClass('check')) {
					return;
				}
				if($(cell).hasClass('edit')) {
					return;
				}
				$(cell).prepend($('<span>').addClass('label').text(texts[j]));
			})
		})
	}
	checkmenu(e) {
		if($(e.target).closest(this.view.checkmenu).get(0) && !this.view.checkmenu.hasClass('active')) {
			this.view.checkmenu.addClass('active');
		} else {
			this.view.checkmenu.removeClass('active');
		}
	}
	alltocheck() {
		this.view.checkmenu.checkbox.prop('checked', true);
		this.view.checkboxes.prop('checked', true);
	}
	alluncheck() {
		this.view.checkmenu.checkbox.prop('checked', false);
		this.view.checkboxes.prop('checked', false);
	}
	onchange() {
		if(!this.view.checkboxes.not(":checked").length) {
			this.view.checkmenu.checkbox.prop('checked', true);
		} else {
			this.view.checkmenu.checkbox.prop('checked', false);
		}
	}
	accordion(e) {
		var $item = $(e.currentTarget);
		var $parent = $item.parent('tr');

		if(!$parent.hasClass('active')) {
			var h = $parent.height();
			$parent.addClass('active');
			var H = $parent.height('auto').height();
			$parent.height(h);
			$parent.stop().animate({height : H}, function() {
				$parent.height('auto');
			});
		} else {
			var h = $parent.height();
			$parent.removeClass('active');
			var H = $parent.height('auto').height();
			$parent.height(h);
			$parent.addClass('active');
			$parent.stop().animate({height : H}, function() {
				$parent.removeClass('active');
				$parent.height('auto');
			});
		}
	}
}
