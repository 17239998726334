import Select from './_DataForm.Select.js';
import CheckGroup from './_DataForm.CheckGroup.js';
import File from './_DataForm.File.js';

export default class DataForm {
	constructor() {
		this.view = $('.data-form');
		this.select = [];
		this.checkgroup = [];
		this.file = [];

		if(!this.view.length) {
			return;
		}
		this.view.find('select').each((i, item) => {
			this.select.push(new Select(item));
		});
		this.view.find('.checkbox-group').each((i, item) => {
			this.checkgroup.push(new CheckGroup(item));
		});
		this.view.find('input[type="file"]').each((i, item) => {
			this.file.push(new File(item));
		})
	}
}
