export default class DataSearch {

	constructor(parent) {
		this.parent = parent;
		this.view = $('.data-search');
		this.pulldown = {};

		if(!this.view.length) {
			return;
		}
		if(this.view.hasClass('js-inited')) {
			return;
		}
		this.view.addClass('js-inited');

		this.view.find('.pulldown').each((i, item) => {
			var instance = new Pulldown($(item), this);

			if(instance.name) {
				this.pulldown[instance.name] = instance;
			}
		});
		Object.keys(this.pulldown).forEach(key => {
			this.pulldown[key].linking();
		});
		this.view.container = this.view.find('.container');
		this.view.opener = this.view.find('.opener');
		this.view.opener.click(e => { this.accordion() });
	}

	accordion() {
		if(!this.view.opener.hasClass('active')) {
			this.view.opener.addClass('active');
			this.view.container.addClass('active');
			var h = this.view.container.height();
			var H = this.view.container.height('auto').height();
			this.view.container.height(h).stop().animate({height : H}, () => {
				this.view.container.height('auto');
			});
		} else {
			this.view.opener.removeClass('active');
			this.view.container.removeClass('active');
			this.view.container.height(h).stop().animate({height : 0}, () => {
				this.view.container.height('');
			});
		}
	}
}

class Pulldown {
	constructor(view, parent) {
		this.parent = parent;
		this.view = view;
		this.view.label = this.view.find('.label');
		this.view.items = this.view.find('.menu a');
		this.view.hidden = this.view.find('input[type="hidden"]');

		this.name = this.view.data('name');
		this.link = this.view.data('link');

		$(document).click(e => {
			this.toggle($(e.target));
		});
		this.view.items.click(e => {
			this.value($(e.currentTarget).data('value'));
			this.linking();
		});
		this.value(this.value());
	}
	toggle(view) {
		if(!this.view.hasClass('active') && view.closest(this.view).length) {
			this.view.addClass('active');
		} else {
			this.view.removeClass('active');
		}
	}
	value(value) {
		if(typeof value != 'undefined') {
			this.view.hidden.val(value);
			this.update(value);
		}
		return this.view.hidden.val();
	}
	update(value) {
		var _label = '';
		var _value = '';

		this.view.items.each(i => {
			if(this.view.items.eq(i).data('value') == value) {
				_value = value;
				_label = this.view.items.eq(i).text();
			}
		})
		this.view.label.text(_label);
		this.view.hidden.val(_value);
	}
	linking() {
		if(!this.link) {
			return;
		}
		this.parent.pulldown[this.link].filter(this.value());
	}
	filter(parent) {
		this.view.items.each(i => {
			if(this.view.items.eq(i).data('parent')) {
				if(this.view.items.eq(i).data('parent') == parent) {
					this.view.items.eq(i).show();
				} else {
					this.view.items.eq(i).hide();

					if(this.view.items.eq(i).data('value') == this.value()) {
						this.value('');
					}
				}
			}
		});
		this.linking();
	}
}
