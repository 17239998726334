export default class File {

	constructor(view) {
		this.view = $(view);
		this.view.change(e => { this.upload(e) });

		this.overlay = new Overlay(this.view.parents('label').siblings('.file-overlay'));
		this.preview = new Preview(this.view.parents('label').siblings('.file-preview'));
		this.input = new Input(this.view.parents('label').siblings('input[type="hidden"]'));//追記
	}
	upload() {
		let driver = Driver.create(this.view.get(0).files[0], this.overlay, this.preview,this.input,{
			upload : {
				// endpoint : '/assets/sample-uploader/upload.php?mode=upload',
				endpoint : '/admin/document/upload?mode=upload',
				chunkbyte : 1024 * 1024 * 0.5,
			},
			encode : {
				// endpoint : '/assets/sample-uploader/upload.php?mode=encode',
				// endpoint : '/admin/document/upload?mode=encode',
			}
		});
		if(driver && driver.check) {
			driver.execute();
		} else {
			alert("対応していないファイル形式です。");
		}
		this.view.get(0).value = '';
	}
}

/**
 * ドライバークラス
 */
class Driver {

	static create(file, settings, overlay, preview,input) {
		if(file.type.match(/^image\//)) {
			return new Driver.Image(file, settings, overlay, preview,input)
		}
		// if(file.type.match(/^video\//)) {
		// 	return new Driver.Video(file, settings, overlay, preview,input)
		// }
		if(file.type.match(/^application\/pdf/)) {
			return new Driver.PDF(file, settings, overlay, preview,input)
		}
		return false;
	}
	constructor(file, overlay, preview,input,settings) {
		this.file = file;
		this.settings = settings;
		this.overlay = overlay;
		this.preview = preview;
		this.input = input;
		this.check = false;
		this.info = {
			TOKEN : '',
			COUNT : 0,
			TOTAL : 0,
		}
		this.init();
	}
	execute() {
		this.overlay.show(Overlay.mode.upload);

		this.read().done(blob => {
			//アップロードサイズのバリデーション処理
			if(this.thisValidator(blob.size,this.maxSize)){
				const reader = new FileReader();
				reader.onloadend = e => {
					this.info.TOTAL = Math.ceil(e.target.result.length / this.settings.upload.chunkbyte);
					this.upload(e.target.result);
				};
				reader.readAsDataURL(blob);
			}else{
				this.overlay.hide();
				alert('アップロードサイズは'+this.maxSize+'MB未満にしてください');
			}
		})
	}
	upload(blob) {
		// const reader = new FileReader();
		// reader.onloadend = e => {
			let sliced = blob.slice(
				this.settings.upload.chunkbyte * this.info.COUNT,
				this.settings.upload.chunkbyte * this.info.COUNT + this.settings.upload.chunkbyte
			);
			this.info.COUNT++;
			$.ajax(this.settings.upload.endpoint,{
				data : sliced,
				type : 'post',
				contentType : 'application/octet-stream',
				processData : false,
				headers : this.info
			})
			.done((response, status, xhr) => {
				this.overlay.show(Overlay.mode.upload, this.info.COUNT, this.info.TOTAL);

				if(!this.info.TOKEN) {
					this.info.TOKEN = response['TOKEN'];
				}
				if(!this.info.TOKEN) {
					response.command = 'error'
					response.errcode = 901;
				}
				// console.log('upload done***', response);

				Object.keys(this.info).forEach(key => {
					if(!response[key] || response[key] != this.info[key]) {
						response.command = 'error'
						response.errcode = 902;
					}
				});
				switch(response.command) {
					case 'upload' : this.upload(blob); break;
					case 'finish' : this.finish(response); break;
					// case 'encode' : this.encode(); break;
					default : this.error(xhr);
				}
			})
			.fail(xhr => {
				this.error(xhr)
			});
		// };
	}
	encode() {
		setTimeout(() => {
			$.ajax(this.settings.encode.endpoint, {
				type : 'post',
				processData : false,
				headers : this.info
			})
			.done((response, status, xhr) => {
				this.info.COUNT = response.COUNT;
				this.info.TOTAL = response.TOTAL;
				this.overlay.show(Overlay.mode.encode, response.COUNT, response.TOTAL);

				Object.keys(this.info).forEach(key => {
					if(response[key] != this.info[key]) {
						console.log(response[key], this.info[key], key);
						response.command = 'error'
						response.errcode = 911;
					}
				});
				console.log('encode done***', response);

				if(response.command == 'encode') {
					return this.encode();
				}
				if(response.command == 'finish') {
					return this.finish(response);
				}
				this.error(xhr);
			})
			.fail(xhr => {
				this.error(xhr)
			});
		}, 1000);
	}
	finish(response) {
		this.overlay.hide();
		// this.preview.show(this.view('/assets/sample-uploader/upload/' + response.filename));
		this.preview.show(this.view('/storage/document/upload/' + response.filename));
		this.input.set(response);
	}
	error(xhr) {
		console.error('error *****', xhr.status, xhr.responseJSON || {}, xhr);
		alert("アップロード中にエラーが発生しました。もう一度お試しください。");
		this.overlay.hide();
	}
	thisValidator(size,maxSize){
		const kb = 1000;
		const mb = Math.pow(kb, 2);
		const res = parseFloat((size / mb).toFixed(2));
		return maxSize > res ? true : false;
	}
	/**
	 * 画像用ドライバークラス
	 */
	static Image = class extends Driver {
		init() {
			this.info.TYPE = 'image';
			this.info.EXT = 'png';
			this.check = true;
			//最大アップロード容量(MB)
			this.maxSize = 100;
		}
		read() {
			const deferred = new $.Deferred;
			const reader = new FileReader();
			const image = document.createElement('img');
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			const maxsize = 750;

			image.onload = () => {
				if(image.width >= image.height) {
					canvas.width = Math.min(image.width, maxsize);
					canvas.height = image.height * (canvas.width / image.width);
				} else {
					canvas.height = Math.min(image.height, maxsize);
					canvas.width = image.width * (canvas.height / image.height);
				}
				context.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
				canvas.toBlob(blob => { deferred.resolve(blob) });
			};
			reader.onload = (e) => {
				image.src = reader.result;
			}
			reader.readAsDataURL(this.file);

			return deferred.promise();
		}
		view(src) {
			return $('<img>').attr({ src : src});
		}
	}
	/**
	 * 動画用ドライバークラス
	 */
	static Video = class extends Driver {
		init() {
			this.info.TYPE = 'video';
			this.info.EXT = this.file.name.match(/\.(mp4|mov|avi|wmv|webm|ogm|mpeg)$/) ? RegExp.$1.toLowerCase() : null;
			this.check = this.info.EXT ? true : false;
			//最大アップロード容量(MB)
			this.maxSize = 100;
		}
		read() {
			const deferred = new $.Deferred;
			deferred.resolve(this.file);
			return deferred.promise();
		}
		view(src) {
			return $('<video>').attr({ src : src, controls : ''});
		}
	}
	/**
	 * PDF用ドライバークラス
	 */
	static PDF = class extends Driver {
		init() {
			this.info.TYPE = 'pdf';
			this.info.EXT = 'pdf';
			this.check = true;
			//最大アップロード容量(MB)
			this.maxSize = 100;
		}
		read() {
			const deferred = new $.Deferred;
			deferred.resolve(this.file);
			return deferred.promise();
		}
		view(src) {
			src = '/assets/js/pdfjs/web/viewer.html?file=' + src;
			return $('<embed>').attr({ src : src});
		}
	}
}

/**
 * オーバーレイ クラス
 */
 class Overlay {
	constructor(view) {
		this.view = view;
		this.view.progress = this.view.find('.progress');
		this.view.progress.bar = this.view.progress.find('.bar');
		this.view.progress.txt = this.view.progress.find('.txt');
		this.view.progress.num = this.view.progress.find('.num');
	}
	show(text, value = 0, total = 0) {
		this.view.addClass('active');
		const ratio = value / total || 0;
		const percentage = Math.round(ratio * 100);
		this.view.progress.bar.width(percentage + '%');
		this.view.progress.txt.text(text);
		this.view.progress.num.text(percentage);
	}
	hide() {
		this.view.removeClass('active');
	}
	static mode = {
		upload : 'アップロード中',
		encode : 'エンコーディング中'
	}
}

/**
 * プレビュークラス
 */
class Preview {
	constructor(view) {
		this.view = view;
	}
	show(view) {
		this.view.children().remove();
		this.view.append($('<div>').append(view));
	}
}

/**追記
 *  インプットクラス
 * input:hiddenにvalueをセットする処理
 */
class Input {
	constructor(view) {
		this.view = view;
	}
	set(response) {
		$(this.view).val(response.filename);
	}
}
