export default class Modal {
	constructor() {
		this.view = $('.modal');
		this.view.triggers = $('.modal-open');
		this.view.triggers.click(e => { this.open(e) });

		this.view.click(e => { this.close(e) });
	}
	open(e) {
		var $modal = null;

		if($(e.currentTarget).data('target')) {
			$modal = $($(e.currentTarget).data('target'));
		}
		if(!$modal) {
			$modal = $(e.currentTarget).next('.modal');
		}
		$modal.addClass('active');
	}
	close(e) {
		if($(e.target).closest('.box').length) {
			if(!$(e.target).closest('.close').length) {
				if(!$(e.target).closest('.cancel').length) {
					return;
				}
			}
		}
		e.preventDefault();
		this.view.removeClass('active');
	}
}
