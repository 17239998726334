export default class Exam {

	static Form = class {
		constructor(parent) {
			this.question = new Exam.Form.Question(this);
			this.choice = new Exam.Form.Choice(this);
			this.numbering();
		}
		numbering () {
			$('.question').each(function(question_index) {
				$(this).find('*').each(function() {
					if(!$(this).attr('name')) {
						return;
					}
					$(this).attr(
						'name',
						$(this).attr('name').replace(/question\[\d*\]/, `question[${question_index}]`)
					);
				});
				$(this).find('.choice').each(function(choice_index) {
					$(this).find('*').each(function() {
						if(!$(this).attr('name')) {
							return;
						}
						$(this).attr(
							'name',
							$(this).attr('name').replace(/\[choice\]\[\d*\]/, `[choice][${choice_index}]`)
						);
						if($(this).attr('name').match(/\[answer_number\]$/)) {
							this.value = choice_index;
						}
					});
					$(this).find('.choice-number').each(function() {
						$(this).text(choice_index + 1);
					});
				});
				if($(this).find('.choice').first().get(0) == $(this).find('.choice').last().get(0)) {
					$(this).find('.choice').addClass('onlyone');
				}
			});
		}
		static Question = class {
			constructor(parent) {
				this.parent = parent;
				this.template = $('#template-question').html();

				$(document).on('click', '.add-question', (e) => {
					this.add($(e.currentTarget));
				})
				$(document).on('click', '.remove-question', (e) => {
					this.remove($(e.currentTarget));
				})
			}
			add ($trigger) {
				$trigger.before(this.template);
				this.parent.numbering();

				for(let i = 0; i < 5; i++) {
					$('.question').last().find('.add-choice').click();
				}
			}
			remove ($trigger) {
				const $form = $trigger.closest('form');
				const $question = $trigger.closest('.question');
				const $questionId = $question.find('.question_id');

				$question.remove();

				if($questionId.get(0)) {
					const removeId = $questionId.val();
					$form.append(`<input type="hidden" name="delete[]" value="${removeId}">`)
				}
				this.parent.numbering();
			}

		}
		static Choice = class {
			constructor(parent) {
				this.parent = parent;
				this.template = $('#template-choice').html();

				$(document).on('click', '.add-choice', (e) => {
					this.add($(e.currentTarget));
				})
				$(document).on('click', '.remove-choice', (e) => {
					this.remove($(e.currentTarget));
				})
			}
			add ($trigger) {
				$trigger.before(this.template);
				this.parent.numbering();
			}
			remove ($trigger) {
				$trigger.closest('.choice').remove();
				this.parent.numbering();
			}
		}
	}
}
