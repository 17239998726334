export default class PageNavi {

	constructor() {
		this.view = $('.page-navi');
		console.log('side')
		if(!this.view.length) {
			return;
		}
		this.view.perpage = this.view.find('select[name="perPage"]');

		this.view.perpage.change((e) => {
			$(e.currentTarget).parents('form').submit();
		})
	}
}
