export default class SlickSlider {
	constructor() {
		this.view = $('.slide-lists');
		if(!this.view.length) {
			return;
		}
		this.view.on('init', function(event, slick) {
			$(this).append('<div class="slick-counter"><span class="current"></span> / <span class="total"></span></div>');
			$('.current').text(slick.currentSlide + 1);
			$('.total').text(slick.slideCount);
		})
		.slick({
			// option here...
		})
		.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			$('.current').text(nextSlide + 1);
		})
		.on('setPosition', function (event,slick) {
			this.setCSS(event,slick);
		}.bind(this));
	}
	setCSS(event,slick){
		$('.slide-item').css('padding-bottom',slick.listWidth+"px");
	}
}
