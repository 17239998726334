export default class Accordion {
	constructor() {
		this.triggers = $('.accordion-trigger');
		this.triggers.click(e => { this.toggle(e) });

		this.triggers.filter('.active').each((i, item) => {
			this.show($(item), true)
		});
	}
	toggle(e) {
		var $trigger = $(e.currentTarget);

		if($(e.target).closest('label').length) {
			return;
		}
		if(!$trigger.hasClass('active')) {
			this.show($trigger);
		} else {
			this.hide($trigger);
		}
	}
	show($trigger, first) {
		var $target = $trigger.data('target') ? $($trigger.data('target')) : $trigger.next('.accordion-target');
		var duration = first ? 0 : 300;
		$trigger.addClass('active');
		$target.addClass('active');
		var h = $target.height();
		var H = $target.height('auto').height();
		$target.height(h);
		$target.stop().animate({height : H}, duration, () => {
			$target.height('auto');
		});
	}
	hide($trigger, first) {
		var $target = $trigger.data('target') ? $($trigger.data('target')) : $trigger.next('.accordion-target');
		var duration = first ? 0 : 300;
		$trigger.removeClass('active');
		$target.removeClass('active');
		$target.stop().animate({height : 0}, duration);
	}
}
